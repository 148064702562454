.KCL_input {
    position: relative;
    font-weight: 400;

    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset;
    }

    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset, 0px 4px 4px $c-blue-04;
    }

    input,
    textarea {
        border-radius: 6px;
        border: 1px solid $c-grey-bd;
        box-shadow: 0px 4px 4px $c-dark-00;
        color: $c-dark-23;
        width: 100%;
    }

    textarea {
        min-height: 100px;
    }

    .search-icon {
        position: absolute;
        top: 14px;
        right: 15px;
        pointer-events: none
    }

    span.currency {
        background: white;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
        color: $c-grey-65;
        user-select: none;
        position: absolute;
        right: 10px; /* Adjust the left value as needed */
        top: 50%; /* Adjust the top value as needed */
        transform: translateY(-50%);

        &.XDR {
            font-size: 8px;
        }
    }

    &.sm {
        input {
            font-size: 13px;
            line-height: 15px;
            height: 30px;
            padding-left: 12px;
            padding-right: 12px;
        }

        &.currency_input {
            width: 120px;
            float: right;

            input {
                width: 120px;
                padding-right: 28px;
            }
        }
        
        .input__label {
            top: 7px;
            left: 12px;
            font-size: 14px;
            line-height: 16px;
        }

        &.is-focused .input__label,
        &.has-value .input__label,
        :-webkit-autofill + .input__label {
            padding: 0 3px;
            top: -5px;
            font-size: 10px;
            line-height: 12px;
        }
        
        > .icon-fa,
        .input__clear {
            font-size: 13px;
            right: 12px;
            top: 8px;
        }
    }

    &.lg {
        input {
            font-size: 16px;
            line-height: 19px;
            height: 45px;
            padding-left: 15px;
            padding-right: 15px;
        }

        textarea {
            font-size: 16px;
            line-height: 19px;
            padding: 13px 15px 0 15px;
        }
        
        .input__label {
            top: 13.5px;
            left: 15px;
            font-size: 16px;
            line-height: 18px;
        }
        
        &.is-focused .input__label,
        &.has-value .input__label,
        :-webkit-autofill + .input__label {
            padding: 0 3px;
            top: -5px;
            font-size: 11px;
            line-height: 13px;
        }
        
        > .icon-fa {
            font-size: 16px;
            right: 15px;
            top: 14px;
        }
    }

    .input__clear {
        position: absolute;
        left: unset;
        right: 0;
        top: 14px;
        font-size: 16px;
        padding-right: 14px;
        background: none;
        border: 0;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        display: none;
    }

    input:hover,
    textarea:hover,
    input:focus,
    textarea:focus,
    input:active,
    textarea:active {
        border-color: $c-blue-22;
        box-shadow: 0px 4px 4px $c-blue-04;
    }

    &.is-focused {
        .input__label {
            color: $c-blue-22;
        }
    }

    .input__label {
        pointer-events: none;
        position: absolute;
        color: $c-grey-65;
        background: #fff;
        z-index: 1;

        @include vendor-prefix(
            transition, (
                top .25s ease-out,
                font-size .25s ease-out,
                line-height .25s ease-out,
                padding .25s ease-out
            )
        );

        @include breakpoint-up(tablet) {
            .main-label {
                display: inline;
            }

            .short-label {
                display: none;
            }
        }

        @include breakpoint-down(tablet) {
            .main-label {
                display: none;
            }

            .short-label {
                display: inline;
            }
        }
    }

    &.is-disabled {
        input:disabled,
        textarea:disabled {
            background: $c-grey-f1 !important;

            &:hover {
                border: $c-grey-bd 1px solid;
                box-shadow: 0px 4px 4px $c-dark-00;
            }
        }

        .input__label {
            color: $c-grey-bd;
            background: $c-grey-f1;
        }
        
        .search-icon {
            color: $c-grey-bd;
        }
    }

    > .icon-fa {
        display: none;
        position: absolute;

        &.eye-icon {
            &.visible {
                display: inline-block;
            }

            &.fa-eye-slash {
                color: $c-grey-65;
                cursor: pointer;
            }

            &.fa-eye {
                color: $c-dark-23;
                cursor: pointer;
            }
        }
    }
    
    .error-icon {
        color: $c-red-db;
    }

    .input-error-message {
        font-size: 12px;
        color: $c-red-db;
        line-height: 1.25;
        display: block;
        padding: 10px 0 0 15px;
    }

    &.has-error {
        input,
        textarea {
            border-color: $c-red-db;
        }

        .input__label {
            color: $c-red-db;
        }

        .input__clear {
            display: flex;
        }
        
        .eye-icon.visible {
            display: none;
        }
    }

    ::placeholder {
        color: $c-grey-65;
    }
    &.is-disabled ::placeholder {
        color: $c-grey-bd;
    }
}
.KCL_hreyfingar-modal {
    .modal-body {
        padding-right: 20px !important;

        @include breakpoint-down(mobile) {
            padding-right: 10px !important;
        }
    }

    table {
        font-size: 15px;

        tr {
            &.is-header-row {
                font-weight: bold;
            }
        }
    }

    .info-col-wrapper {
        display: flex;
        gap: 20px 60px;
        flex-wrap: wrap;
        align-items: center;

        .info-col {
            .info-item {
                height: 50px;
                
                @include breakpoint-down(tablet) {
                    height: auto;
                }
            }
        }
    }

    .table-wrapper {
        padding-top: 60px;

        @include breakpoint-down(mobile-medium) {
            padding-top: 30px;
        }
    }

    .footer-info {
        // Flex
        display: flex;
        flex-flow: column nowrap;

        div {
            // Flex
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            // Spacing
            padding-top: 16px;

            span {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
            }

            &:first-child {
                padding-top: 0px;
                height: 50px;

                span {
                    &:last-child {
                        padding-left: 60px;
                        border-bottom: 2px solid $c-grey-e9;
                    }

                }
            }

            &:not(:first-child) {
                font-size: 15px;
                line-height: 18px;
            }
        }
    }

    .alert-wrapper {
        padding-top: 50px;
        white-space: nowrap;

        .KCL_alert {
            width: min-content;
            
            .icon-and-header {
                width: min-content;
            }
        }
    }
}
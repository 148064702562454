.KCL_company-financial-info {
    .display-box__body {
        @include breakpoint-down(mobile-medium) {
            margin-right: -15px;
        }
    }

    .KCL_table {
        table {
            table-layout: fixed;
        }
        
        .text-bold {
            font-weight: 700;
        }

        .text-italic {
            font-style: italic;
        }

        .text-group-head {
            font-weight: 700;
        }

        th, td {
            &:first-child {
                width: 200px;

                @include breakpoint-down(mobile) {
                    width: 172px;
                }
            }

            &:last-child {
                width: 100px;
            }
        }

        th > .title-span > .text-span.column-with-arrow {
            width: 100%;

            .header-container {
                display: flex;
                justify-content: space-between;
            }
        }
        
        .year-slider-arrow {
            cursor: pointer;
        }

        .tab-switcher-row {
            padding: 0px;

            td {
                padding: 0px;

                .tabs-wrapper {
                    // push tabs
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: flex-end;

                    .KCL_tabs {
                        width: 100%;

                        .tabs__body {
                            padding-top: 0px;
                        }
                    }
                }
            }
        }

        .ratios-title {
            white-space: break-spaces;

            .KCL_tooltip {
                margin-left: 5px;
            }
        }
    }
}
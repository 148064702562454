.KCL_exchange-notices-page {
    background: $c-grey-f8;
    padding-bottom: 100px;

    @include breakpoint-down(mobile) {
        padding-bottom: 80px;
    }

    .search-box {
        background: $c-grey-f1;
        padding: 30px 20px 28px;
        margin: -22px -20px 20px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        .KCL_small-search {
            max-width: none;
        }

        @include breakpoint-down(mobile) {
            padding: 20px 15px;
            margin: -20px -15px 10px;
            border-radius: 0;
        }
    }

    .KCL_table {
        table {
            @include breakpoint-up(tablet) {
                table-layout: fixed;

                th, td {
                    &:first-child {
                        width: 80px;
                    }
                    &:nth-child(2) {
                        width: 66px;
                    }
                    &:nth-child(3) {
                        width: 91px;
                    }
                    &:nth-child(4):not(:last-child) {
                        width: 230px;
                    }
                }
            }
        }
        tr {
            &:hover {
                background: $c-teal-f2;
            }
            max-width: 100%;
        }
        td {
            > a:not(.KCL_link) {
                text-decoration: none;
                width: 100%;
                height: 100%;
                display: inline-block;

                &:hover {
                    color: inherit;
                }

                &.clickableRow {
                    padding-top: 10px;
                }
            }

            .KCL_link {
                display: inline-block;
                overflow: hidden;
                max-width: 220px;
            }

            &.news-title {
                @include breakpoint-down(tablet) {
                    max-width: 300px;
                }

                > a:not(.KCL_link).clickableRow {
                    overflow-x: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-top: 5px;
                    height: auto;
                }
            }

            &.feed-name {
                padding: 12px 5px 7px;
                max-width: 230px;
            }
        }
    }

    .attachments-icon {
        font-size: 23px;
        color: $c-blue-45;
        position: relative;
        margin-right: 8px;

        .attachments-nr {
            font-size: 10px;
            font-weight: 700;
            color: $c-white;
            position: absolute;
            left: 6px;
            bottom: 4px;
        }
    }

    .load-more-button-wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-top: 20px;
    }

    .grid-items {
        .grid-item {
            &.grid-item--3of4 {
                float: left;
            }

            &.grid-item--1of4 {
                float: right;
            }
        }
    }
}
.KCL_frettavakt {
    padding-bottom: 100px;

    .shell {
        .newsWatcher_wrapper {
            display: flex;
            column-gap: 80px;
            row-gap: 40px;
            flex-wrap: wrap;

            @include breakpoint-down(small-desktop) {
                flex-direction: column;
                margin-bottom: 40px;
            }

            .description_newsWatcher {
                flex: 2;

                .KCL_link {
                    margin-top: 19px;
                }
            }

            .KCL_alert {
                flex: 1;
            }
        }
    }

    .KCL_frettavakt-container {
        .row {
            justify-content: space-between;

            .main-content-wrapper {
                width: 62.5%;

                @include breakpoint-down(small-desktop) {
                    width: 100%;
                }
                
                .search__box {
                    margin-bottom: 25px;
                    display: flex;
                    flex-flow: column nowrap;

                    @include breakpoint-down(mobile) {
                        margin-bottom: 15px;
                    }

                    .input-and-filter {
                        display: flex;
                        flex-flow: row nowrap;
                    }

                    .KCL_small-search {
                        flex-grow: 1;
                    }

                    .filter-wrapper {
                        .KCL_filter {
                            margin-top: 10px;
                            margin-left: 5px;
                        }

                        ul li button {
                            text-align: left;
                        }

                        // Hide it on really big screens.
                        @include breakpoint-up(small-desktop) {
                            display: none;
                        }
                    }
                }

                .load-more-button-wrapper {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    margin-top: 40px;
                }
            }

            .source-filter-wrapper {
                // Only show it it on really big screens.
                @include breakpoint-down(small-desktop) {
                    display: none;
                }

                .veitur {
                    margin-top: 37px;
                }

                .source-filters {
                    display: flex;
                    flex-flow: row wrap;

                    &.source-filters--category {
                        flex-direction: column;
                        gap: 5px;
                        margin-top: 10px;

                        .category-wrapper {
                            padding: 10px 20px 10px 15px;
                            border: 1px solid $c-blue-d4;
                            border-radius: 6px;
                            &:hover:not(.is-open) {
                                background-color: $c-teal-f2;
                            }

                            .category-item{
                                display: flex;
                                align-items: center;
                                gap: 10px;
        
                                .fa-icon{
                                    color: $c-blue-22;
                                    transition: transform 0.4s;
                                    cursor: pointer;
                                    &.rotate-open {
                                        transform: rotate(180deg);
                                    }
                                }
                                .category-header{
                                    flex: 1;
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;
                                    justify-content: space-between;

                                    .fa-icon {
                                        color: $c-dark-23;
                                    }
                                    span {
                                        color: $c-blue-1d;
                                    }
                                    span.category-name {
                                        font-size: 16px;
                                        font-weight: 500;
                                        margin-right: 6px;
                                    }
                                    span.category-length {
                                        font-size: 14px;
                                        font-weight: 400;
                                    }
                                }
                            }
                            .category-item__content {
                                // overflow-y: auto;
                                .category-item__body {
                                    padding: 10px 0 5px;

                                    .KCL-filter-item {
                                        margin-left: 0;
                                        margin-right: 5px
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .vaktarinn-wrapper {
                margin-top: 20px;
                display: flex;
                flex-direction: column;

                &.tabletView {
                    display: none;

                    @include breakpoint-down(small-desktop) {
                        display: flex;
                    }
                }

                .vaktarinn-description {
                    text-align: left;
                }

                .vaktarinn-button-wrapper {
                    display: flex;
                    flex-direction: row;
                    gap: 15px;

                    a.KCL_button {
                        width: fit-content;

                        &:nth-child(1) {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
    }
}

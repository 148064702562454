.KCL_filter {
    position: relative;
    
    .settingsButton {
        background: none;
        border: none;
        font-size: 16px;
        padding: 5px 7px;
        width: 30px;
        height: 30px;
        color: $c-dark-23;

        .circle {
            border: transparent solid 1px;
            position: absolute;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            right: 3px;
            top: 0px;
            color: $c-red-ff7;
        }

        &:hover {
            color: $c-blue-1d;
        }
    }

    .filter-list {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 5;
        background: $c-white;
        box-shadow: 0px 2px 30px 3px $c-blue-22;
        border-radius: 6px;
        padding: 15px 15px 19px 20px;

        &.is-open {
            display: block;
        }

        .list-wrapper {
            max-height: 400px;
            overflow-y: auto;
            overflow-x: hidden;
            margin-top: 20px;

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                background: $c-teal-f2;
                border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background: $c-blue-1d;
                border-radius: 5px;
    
                &:hover {
                   background: $c-blue-04;
                }
            }

            ul {
                padding-left: 0;
                padding-top: 15px;
                list-style: none;
                
                &.list-grid {
                    column-gap: 5px;
                    column-count: 4;

                    @include breakpoint-down(tablet) {
                        column-count: 3;
                    }

                    @include breakpoint-down(mobile) {
                        column-count: 2;
                    }

                    @include breakpoint-down(mobile-medium) {
                        column-count: 1;
                    }
                }

                &.list-flow {
                    display: flex;
                    flex-flow: row wrap;
                    width: 700px;

                    @include breakpoint-down(tablet) {
                        width: 600px;
                    }

                    @include breakpoint-down(mobile) {
                        width: 400px;
                    }

                    @include breakpoint-down(mobile-medium) {
                        width: 250px;
                    }
                }
                &.list-categories {
                    display: flex;
                    flex-direction: column;
                    width: 700px;
                    gap: 5px;
                    
                    .category-wrapper {
                        padding: 10px 20px 10px 15px;
                        border: 1px solid $c-blue-d4;
                        border-radius: 6px;
                        &:hover:not(.is-open) {
                            background-color: $c-teal-f2;
                        }
                        .category-item{
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            .fa-icon{
                                color: $c-blue-22;
                                transition: transform 0.4s;
                                cursor: pointer;
                                &.rotate-open {
                                    transform: rotate(180deg);
                                }
                            }
                            .category-header{
                                flex: 1;
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                justify-content: space-between;

                                .category-name-wrapper{
                                    white-space: nowrap;       /* Prevents text from wrapping to next line */
                                    overflow: hidden;          /* Hides any content that overflows the container */
                                    text-overflow: ellipsis;   /* Adds the ellipsis when text overflows */
                                }

                                .fa-icon {
                                    color: $c-dark-23;
                                }
                                span {
                                    color: $c-blue-1d;
                                }
                                span.category-name {
                                    font-size: 16px;
                                    font-weight: 500;
                                    margin-right: 6px;
                                }
                                span.category-length {
                                    font-size: 14px;
                                    font-weight: 400;
                                }
                            }
                        }
                        .category-item__content {
                            // overflow-y: auto;
                            .category-item__body {
                                padding: 10px 0 5px;

                                .KCL-filter-item {
                                    margin-left: 0;
                                    margin-right: 5px
                                }
                            }
                        }
                    }

                    @include breakpoint-down(tablet) {
                        width: 600px;
                    }

                    @include breakpoint-down(mobile) {
                        width: 400px;
                        .category-header .category-name-wrapper{
                            max-width: 201px;
                        }
                    }

                    @include breakpoint-down(mobile-medium) {
                        width: 250px;
                        .category-header .category-name-wrapper{
                            max-width: 145px;
                        }
                    }
                }
            }
            
            li {
                break-inside: avoid;
            }
        }

        .closeButton {
            background: none;
            border: none;
            float: right;
            font-size: 16px;
            line-height: 19px;
        }
    }
}